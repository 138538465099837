import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/hegel/hegel/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "primitive-types"
    }}>{`Primitive Types`}</h1>
    <hr></hr>
    <p>{`Imagine type as a Set of different values. Some of them are finite and some are not. In nowadays JavaScript has 7 primitive types:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#null-and-undefined"
        }}>{`Null`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#null-and-undefined"
        }}>{`Undefined`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#boolean"
        }}>{`Boolean`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#number"
        }}>{`Number`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#number"
        }}>{`BigInt`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#string"
        }}>{`String`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#symbol"
        }}>{`Symbol`}</a></li>
    </ul>
    <p>{`Hegel has not only the same types, but also adds several new types. Let's discover each of them:`}</p>
    <h2 {...{
      "id": "null-and-undefined"
    }}>{`Null and Undefined`}</h2>
    <p>{`The most primitive types in JavaScript are `}<inlineCode parentName="p">{`null`}</inlineCode>{` and `}<inlineCode parentName="p">{`undefined`}</inlineCode>{`, because each of them contains only one value.
You can define variable with this datatype in Hegel:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`let emptyValue: null = null;
let undefinedValue: undefined = undefined;
`}</code></pre>
    <p>{`As you can see `}<inlineCode parentName="p">{`null`}</inlineCode>{` and `}<inlineCode parentName="p">{`undefined`}</inlineCode>{` types have the same name as their literals, but some datatypes do not. In Hegel you can annotate your variables/object properties with both literal value and datatype name.`}</p>
    <h2 {...{
      "id": "boolean"
    }}>{`Boolean`}</h2>
    <p>{`The second primitive type is `}<inlineCode parentName="p">{`boolean`}</inlineCode>{`, because it contains only two values: `}<inlineCode parentName="p">{`true`}</inlineCode>{` and `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// Using literal as a datatype
let canIContain4Values: false = false;
let canIUseLiteralAsTypeAnnotation: true = true;

// Using expressions
let canIUseWithExpressions: boolean = !canIContain4Values;
`}</code></pre>
    <h2 {...{
      "id": "number"
    }}>{`Number`}</h2>
    <p>{`JavaScript Number datatype presents any signed 64-bit number. JavaScript has not different datatypes for floating or unsigned numbers. -1, 2.7182 and 0xff will have the same datatype - number. Also, JavaScript has "special" identifiers: Infinity and NaN which also included in number datatype (And in JavaScript is existed `}<inlineCode parentName="p">{`-0`}</inlineCode>{`, but this is a long story).
In Hegel you can annotate your variable/object property with `}<inlineCode parentName="p">{`number`}</inlineCode>{` datatype name or with literal in different format (hexademical, binary, exponential and etc.)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`let decimal: 6 = 6;
let hex: 0xf00d = 0xf00d;
let binary: 0b1010 = 0b1010;
let octal: 0o744 = 0o744;
let anyNumber: number = decimal + hex + binary + octal;
`}</code></pre>
    <h2 {...{
      "id": "bigint"
    }}>{`BigInt`}</h2>
    <p><inlineCode parentName="p">{`BigInt`}</inlineCode>{` was added in ES2019 and presents any integer number without bits limitations.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`let reallyBigNumber: bigint = 2n ** 255n;
`}</code></pre>
    <p><strong parentName="p">{`Warning`}</strong>{`: `}<inlineCode parentName="p">{`BigInt`}</inlineCode>{` is not subtype or super type for `}<inlineCode parentName="p">{`Number`}</inlineCode>{` data type. Hegel will show you an error if you try to use bigint as number or number as bigint. Also, in JavaScript you can't mix number and bigint in math operators, so, Hegel will show you an error too.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// Error: Type "bigint" is incompatible with type "number"
const usageBigIntAsNumber: number = 4n + 2n;

// Error: Type "number" is incompatible with type "bigint"
const usageNumberAsBigInt: bigint = 4 + 2;

// Error: Type "4n" is incompatible with type "number"
4 * 4n;

// Error: Type "255" is incompatible with type "bigint"
2n ** 255;
`}</code></pre>
    <h2 {...{
      "id": "string"
    }}>{`String`}</h2>
    <p>{`Unlike String in other programming languages, JavaScript's String has a value (not reference) behavior. So, any characters set wrapped in single quote ('), double quote (") or backtick/backquote (\`) will be a valid JavaScript string literal. In Hegel you can annotate variable/object property with both literal or string datatype name, the same as with other datatypes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const firstName: "Robert" = "Robert";
const lastName: "Paulson" = "Paulson";
const fullName: string = firstName + " " + lastName;
const sentence: string = \`His name is \${fullName}.\`;
`}</code></pre>
    <p>{`JavaScript implicitly converts other types of values into strings by concatenating them.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`44 + "6"; // 446
`}</code></pre>
    <p>{`Hegel implements a strong type system, so it will accept only strings when concatenating them or when template literal is used.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const world = {
  toString() {
    return "World";
  },
};

"Hello " + "World"; // 👌!

// Error: Type "42" is incompatible with type "string"
"Hello " + 42;

// Error: Type "{ toString: () => string }" is incompatible with type "string"
"Hello " + world;

// Error: Type "{ toString: () => string }" is incompatible with type "string"
\`Hello, \${world}\`;
`}</code></pre>
    <p>{`To prevent error you need explicity convert value to string:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const world = {
  toString() {
    return "World";
  },
};

"Hello " + "World"; // 👌!
"Hello " + String(42); // 👌!
"Hello " + String(world); // 👌!
\`Hello, \${String(world)}\`; // 👌!
`}</code></pre>
    <h2 {...{
      "id": "symbol"
    }}>{`Symbol`}</h2>
    <p>{`Symbol is another "new" datatype which was included in language starting from ECMAScript 2015. Symbols are created by `}<inlineCode parentName="p">{`Symbol`}</inlineCode>{` function invocation in JavaScript. Hegel provides `}<inlineCode parentName="p">{`symbol`}</inlineCode>{` datatype that can be used as type annotation.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const unique: symbol = Symbol("unique");
`}</code></pre>
    <p><strong parentName="p">{`Warning`}</strong>{`: Symbol datatype has not any literal, so you can not use symbol values as datatype.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// Error: Unexpected token
const unique: Symbol("unique") = Symbol("unique");
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      